import React from "react";

const HeadingGradient = ({ headingHTML }) => {
  return (
    <span
      className="glossary text-3.25xl sm:text-4.5xl text-purple-1100 leading-10 font-manrope sm:leading-12 tracking-tighter_3 font-semibold  "
      dangerouslySetInnerHTML={{ __html: headingHTML }}
    />
  );
};

export default HeadingGradient;
